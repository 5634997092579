import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans} from 'gatsby-plugin-react-i18next';
import { Button, SassyText, Section, SectionWrapper, Title } from './core';
import { useMixpanel } from '../context/MixpanelContext';
import { isBrowser } from "../utils/browserUtils"

import Handshake from '../assets/images/handshake.svg';
import useHindiLangFont from '../hooks/useHindiLangFont';
import LeadForm from './LeadForm';

export const CTASection = () => {
  const {hindiBlack, hindiBold} = useHindiLangFont()
  const {t} = useTranslation(['ctaSection', 'common'])
  const mixpanel = useMixpanel()
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    let mxEventName = ""
    let pathname = ""

    if (isBrowser) {
      pathname = window.location.pathname
    }

    switch (pathname) {
      case "/offer": 
        mxEventName = "Clicked-Down - 'Get a Quote' - Offer"
        break;
      default: mxEventName = ""
    }

    if (mxEventName) mixpanel.track(mxEventName)

    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  return (
    <>
      {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
      <Section background="#fff" height="600px">
        <SectionWrapper>
          <Container>
            <HeadingContainer>
              <Title style={{ marginBottom: '40px' }} className={hindiBlack}>
                <Trans i18nKey="CTASectionTitle">
                  Get the <SassyText>best protection</SassyText>
                  <br />
                  for your business
                </Trans>
              </Title>
              <Button label={t('CTALabels.GetAQuote')} wide onClick={handleQuoteModalOpen} className={hindiBold} />
            </HeadingContainer>
            <Handshake className="hand-icon" />
          </Container>
        </SectionWrapper>
      </Section>
    </>
  );
};

const HeadingContainer = styled.div`
  margin-right: 80px;

  @media screen and (max-width: 768px) {
    margin-right: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hand-icon {
    margin-top: 50px;
    margin-left: 80px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
      width: 250px;
    }
  }
`;
