import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-react-i18next';

import Fire from '../assets/images/fire_icon.svg';
import Group from '../assets/images/group_icon.svg';
import Clock from '../assets/images/clock_icon.svg';
import { StaticImage } from 'gatsby-plugin-image';
import useHindiLangFont from '../hooks/useHindiLangFont';
// import Care from '../assets/images/care.svg';
// import Convenience from '../assets/images/convenience.svg';
// import Credibility from '../assets/images/credibility.svg';

export const Card = ({ title, content, icon, link, linkText, type }) => {
  const {hindiBold, hindiRegular} = useHindiLangFont()
  let iconElement;
  switch (icon) {
    case 'fire': {
      iconElement = <Fire className="icon" />;
      break;
    }
    case 'liability': {
      iconElement = <Group className="icon" />;
      break;
    }
    case 'comingsoon': {
      iconElement = <Clock className="icon" />;
      break;
    }
    case 'care': {
      iconElement = <div style={{width: 'min(275px, 100%)'}}><StaticImage alt="care" src="../assets/images/care.webp" /></div>;
      break;
    }
    case 'convenience': {
      iconElement = <div style={{width: 'min(275px, 100%)'}}><StaticImage alt="care" src="../assets/images/convenience.webp" /></div>;
      break;
    }
    case 'credibility': {
      iconElement = <div style={{width: 'min(275px, 100%)'}}><StaticImage alt="care" src="../assets/images/credibility.webp" /></div>;
      break;
    }
    default: {
      iconElement = <Fire className="icon" />;
      break;
    }
  }

  return (
    <StyledCard type={type}>
      {iconElement}
      <h5 className={hindiBold}>{title}</h5>
      <p className={hindiRegular}>{content}</p>
      {link && <StyledLink className={hindiRegular} to={link}>{linkText}</StyledLink>}
    </StyledCard>
  );
};

const StyledCard = styled.div`
  /* width: ${({ type }) => `${type === 'transparent' ? '370px' : '390px'}`}; */
  height: ${({ type }) => `${type === 'transparent' ? '400px' : '300px'}`};
  margin: ${({ type }) =>
    `${type === 'transparent' ? '0px 45px' : '0px 10px'}`};
  background: ${({ type }) =>
    `${type === 'transparent' ? 'transparent' : '#ffffff'}`};
  border: ${({ type }) =>
    `${
      type === 'transparent' ? 'none' : '0.5px solid rgba(139, 142, 209, 0.5)'
    }`};
  box-sizing: border-box;
  box-shadow: ${({ type }) =>
    `${type === 'transparent' ? '' : '0px 2px 4px rgba(0, 0, 0, 0.08)'}`};
  border-radius: 12px;
  position: relative;
  z-index: 30;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 33%;
  overflow: visible;

  @media screen and (min-width: 768px) {
    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 768px) {
    min-width: ${({ type }) => `${type === 'transparent' ? '100%' : '240px'}`};
    margin-bottom: 20px;
  }

  h5 {
    color: #4d4d4d;
    margin-top: ${({ type }) => `${type === 'transparent' ? '10px' : '65px'}`};
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;

    @media screen and (max-width: 1000px) {
      font-size: 18px;
    }
  }

  p {
    margin-top: 24px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #6d6d6d;
    padding: ${({ type }) =>
      `${type === 'transparent' ? '0px 5px' : '0px 40px'}`};
    text-align: ${({ type }) =>
      `${type === 'transparent' ? 'center' : 'center'}`};

    @media screen and (max-width: 1000px) {
      font-size: 16px;
      line-height: 20px;
      padding: ${({ type }) =>
        `${type === 'transparent' ? '0px 5px' : '0px 15px'}`};
    }
  }

  .icon {
    position: absolute;
    top: -40px;

    @media screen and (max-width: 1000px) {
      height: 60px;
      top: -30px;
    }
  }
`;

const StyledLink = styled(Link)`
  padding: 6px 12px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #4d4d4d;
  margin: auto 16px 16px auto;
  background: #fff2ea;
  border-radius: 9px;
`;
