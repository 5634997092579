import React from "react";
import styled, { css } from "styled-components";
import loadable from "@loadable/component";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";
import { SassyText, StyledLink, Text } from "./core/commonExports";
import { Button } from "./core/Button";

import { useMixpanel } from "../context/MixpanelContext";

import VerakLogo from "../assets/images/logo_color.svg";

import ChevronDown from "../assets/images/chevron_down.svg";
import Globe from "../assets/images/globe.svg";
import useSelectedLanguage from "../hooks/useSelectedLanguage";
import getLangRoute from "../utils/getLangRoute";
import nonTranslatedPages from "../utils/getNonTranslatedPages";
import useHindiLangFont from "../hooks/useHindiLangFont";
import { isBrowser } from "../utils/browserUtils";
import { getPageName } from "../utils/getCurrentPageName";
import { LeadFormProvider } from "../context/LeadFormContext";
import { OnDesktop, OnMobile } from "./DesktopMobileComps";
import checkOfficeHours from "../utils/checkOfficeHours";

// const CallSheduleModal = loadable(() => import("./LPCallScheduleModal"))
const LeadForm = loadable(() => import("./LeadForm"));

// const LanguageSelectionModal = loadable(() => import("./LanguageSelectionModal"));

// to strip any trailing backslashes, if present
const getCleanedPathname = (path) =>
  path !== "/" ? path.replace(/\/+$/, "") : path;

const LanguageSwitcher = ({ onClick = () => {} }) => {
  const { i18n } = useTranslation();
  const isHindi = i18n.language === "hi";

  const handleLangChange = (lang) => {
    i18n.changeLanguage(lang);
    isBrowser && window.localStorage?.setItem("lang", lang);
    const changedRoute = getLangRoute(lang);
    changedRoute && navigate(changedRoute);
  };

  return (
    <LanguageSwitcherBtn
      onClick={onClick}
      label={
        <>
          <Text
            className="on-desktop"
            fontSize="10px"
            fontWeight="600"
            color="rgba(153, 153, 153, 1)"
            style={{ margin: "0", lineHeight: "15px" }}
          >
            Language/ <span className="hindi-regular">भाषा</span>
          </Text>
          <div
            className="d-flex"
            style={{
              alignItems: "center",
              justifyContent: "center",
              fontSize: "14px",
              fontWeight: "bold",
              gap: "10px",
            }}
          >
            <Globe />
            <SassyText
              className={isHindi ? "hindi-regular" : ""}
              style={{ lineHeight: "18px" }}
            >
              {isHindi ? "हिन्दी" : "English"}
            </SassyText>
            <ChevronDown style={{ transform: "rotate(-180deg)" }} />
          </div>
          <ul className="drop" style={{ width: "130px", left: "0" }}>
            <li
              className="hindi-regular"
              onClick={() => handleLangChange("hi")}
            >
              हिन्दी
            </li>
            <li onClick={() => handleLangChange("en")}>English</li>
          </ul>
        </>
      }
    />
  );
};

export default function Navbar({ active }) {
  const { t, i18n } = useTranslation("navbar");
  const mixpanel = useMixpanel();
  useSelectedLanguage();
  const { hindiBold, hindiRegular } = useHindiLangFont();

  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);
  // const [isCallScModalOpen, setIsCallScModalOpen] = React.useState(false)
  // const [isLangModalOpen, setIsLangModalOpen] = React.useState(() => {
  //   let prevSelectedLang;
  //   if (isBrowser) {
  //       prevSelectedLang = window.localStorage?.getItem('lang') || ''
  //   }
  //   return !Boolean(prevSelectedLang)
  // });
  const [isNavActive, setIsNavActive] = React.useState(false);
  const [activeDropdown, setActiveDropdown] = React.useState({
    products: false,
    blogs: false,
    aboutus: false,
    businessinsproducts: false,
  });

  const showLangSwitcher =
    isBrowser &&
    !nonTranslatedPages.includes(
      getCleanedPathname(window.location?.pathname?.replace("/hi/", "/"))
    );

  const isHindi = showLangSwitcher && i18n.language === "hi";

  const isSafetyPlanLP = isBrowser && window.location.pathname === "/safety-plan"

  const handleActiveDropdown = (item) => {
    setActiveDropdown((val) => {
      if (item === "products" && activeDropdown.products) {
        return { ...val, [item]: !val[item], businessinsproducts: false };
      }
      return { ...val, [item]: !val[item] };
    });
  };

  const handleNavbarDrop = () => {
    setIsNavActive((value) => !value);
  };

  // const handleCallScModalOpen = () => {
  //   mixpanel.track("Clicked 'Contact Us' - Nav Bar");
  //   setIsCallScModalOpen(true)
  // }

  // const handleCallScModalClose = () => setIsCallScModalOpen(false)

  const handleQuoteModalOpen = () => {
    const pageName = getPageName();
    let ctaName = isSafetyPlanLP ? "Get Plans" : "Get a Quote"
    let eventName = `Clicked '${ctaName}' - Nav Bar`;
    if (pageName) {
      eventName += ` (${pageName})`;
    }
    mixpanel.track(eventName);
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => setIsQuoteModalOpen(false)

  // const handleLangModalClose = () => {
  //   setIsLangModalOpen(false)
  // }

  React.useEffect(() => {
    const allNavLinks = document.querySelectorAll(".mx-nav-event");
    allNavLinks?.forEach((link) => {
      link.addEventListener("click", (event) => {
        mixpanel.track(`Clicked ${event.target.innerText} - Nav Bar`);
      });
    });
  }, []); // eslint-disable-line

  const handleHotlineClick = () => (window.location.href = "tel:+919076277062");

  const isAfterHours = !checkOfficeHours();

  let ctaLabelMobile = t(`CTALabels.${isAfterHours ? "GetAQuote" : "CallUs"}`)
  if (isSafetyPlanLP && isAfterHours) {
    ctaLabelMobile = "Get Plans"
  }

  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? (
          <LeadForm formType={isSafetyPlanLP ? "safety-plan" : "website"} onModalClose={handleQuoteModalClose} />
        ) : null}
      </LeadFormProvider>
      {/* {isCallScModalOpen ? <CallSheduleModal handleModalClose={handleCallScModalClose} /> : null} */}
      {/* {showLangSwitcher && isLangModalOpen ? <LanguageSelectionModal onModalClose={handleLangModalClose} /> : null} */}
      <NavbarWrapper className="fixed-to-top ">
        <Wrapper>
          <StyledLink className="display-flex flex-direction-column" to="/">
            <Logo alt="Verak Logo" />
            <LogoText>Girnar Insurance Brokers Private Limited</LogoText>
          </StyledLink>
          <NavItems isHindi={isHindi}>
            <NavList isHindi={isHindi}>
              <ListItem active={active === "products"} className={hindiRegular}>
                {t("Products.Text")}
                <ul className="drop">
                  <li className={`${hindiRegular} drop-with-children`}>
                    <ListDropdownItem>
                      <StyledLink
                        to="/fire"
                        className={`mx-nav-event ${hindiRegular}`}
                      >
                        {t("Products.Items.0.Name")}
                      </StyledLink>
                      <ul className="drop-child">
                        <StyledLink
                          to="/business-type/shop"
                          className="mx-nav-event"
                        >
                          <li className={hindiRegular}>
                            {t("Products.Items.0.Shop")}
                          </li>
                        </StyledLink>
                        <StyledLink
                          to="/business-type/restaurant"
                          className="mx-nav-event"
                        >
                          <li className={hindiRegular}>
                            {t("Products.Items.0.Restaurant")}
                          </li>
                        </StyledLink>
                        <StyledLink
                          to="/business-type/factory"
                          className="mx-nav-event"
                        >
                          <li className={hindiRegular}>
                            {t("Products.Items.0.Factory")}
                          </li>
                        </StyledLink>
                        <StyledLink
                          to="/business-type/storage"
                          className="mx-nav-event"
                        >
                          <li className={hindiRegular}>
                            {t("Products.Items.0.Storage")}
                          </li>
                        </StyledLink>
                        <StyledLink
                          to="/business-type/service-business"
                          className="mx-nav-event"
                        >
                          <li className={hindiRegular}>
                            {t("Products.Items.0.Service")}
                          </li>
                        </StyledLink>
                      </ul>
                    </ListDropdownItem>
                  </li>
                  <StyledLink to="/liability" className="mx-nav-event">
                    <li className={hindiRegular}>{t("Products.Items.1")}</li>
                  </StyledLink>
                  <StyledLink to="/safety-plan" className="mx-nav-event">
                    <li className={hindiRegular}>{t("Products.Items.2")}</li>
                  </StyledLink>
                </ul>
              </ListItem>
              <ListItem active={active === "claims"} className="mx-nav-event">
                <StyledLink className={hindiRegular} to="/claims">
                  {t("Claims")}
                </StyledLink>
              </ListItem>
              <ListItem
                active={active === "endorsements"}
                className="mx-nav-event"
              >
                <StyledLink className={hindiRegular} to="/endorsements">
                  {t("Endorsements")}
                </StyledLink>
              </ListItem>
              <ListItem className={hindiRegular}>
                {t("Blogs.Text")}
                <ul className="drop">
                  <Link
                    href="https://insights.verak.in/"
                    className="mx-nav-event"
                  >
                    <li className={hindiRegular}>{t("Blogs.Items.0")}</li>
                  </Link>
                  <Link href="https://blog.verak.in/" className="mx-nav-event">
                    <li className={hindiRegular}>{t("Blogs.Items.1")}</li>
                  </Link>
                </ul>
              </ListItem>
              <ListItem active={active === "aboutus"}>
                {t("AboutUs.Text")}
                <ul className="drop">
                  <StyledLink to="/our-company" className="mx-nav-event">
                    <li className={hindiRegular}>{t("AboutUs.Items.0")}</li>
                  </StyledLink>
                  <StyledLink to="/our-team" className="mx-nav-event">
                    <li className={hindiRegular}>{t("AboutUs.Items.1")}</li>
                  </StyledLink>
                  <StyledLink to="/our-customers" className="mx-nav-event">
                    <li className={hindiRegular}>{t("AboutUs.Items.2")}</li>
                  </StyledLink>
                </ul>
              </ListItem>
            </NavList>
            <Flex style={{ width: "fit-content" }}>
              {showLangSwitcher && (
                <span className="on-desktop">
                  <LanguageSwitcher />
                </span>
              )}
              <OnMobile style={{width: "auto"}}>
                <CustomButton
                  type={isAfterHours ? isSafetyPlanLP ? "flatdesign" : "primary" : "secondary"}
                  label={ctaLabelMobile}
                  minWidth="160px"
                  onClick={
                    isAfterHours ? handleQuoteModalOpen : handleHotlineClick
                  }
                  className={hindiBold}
                />
              </OnMobile>
              <OnDesktop style={{width: "auto"}}>
                <CustomButton
                  label={t('CTALabels.GetAQuote')}
                  minWidth="160px"
                  onClick={handleQuoteModalOpen}
                  className={hindiBold}
                />
              </OnDesktop>
              <LoginLinkDesktop
                href="https://client.verak.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Login")}
              </LoginLinkDesktop>
              <Burger onClick={handleNavbarDrop} active={isNavActive}>
                <div className="line1" />
                <div className="line2" />
                <div className="line3" />
              </Burger>
            </Flex>
          </NavItems>
        </Wrapper>
      </NavbarWrapper>

      <MobileNavDropdown active={isNavActive}>
        <DropdownItem>
          <DropdownItemHeader
            onClick={() => handleActiveDropdown("products")}
            className={hindiBold}
          >
            {t("Products.Text")}
            <ChevronDown className="chevron-down" />
          </DropdownItemHeader>
          <ul className={activeDropdown["products"] ? "dropped-down" : ""}>
            <li style={{ paddingRight: "0", paddingBottom: "0" }}>
              {/* <FireIcon className="navbar-drop-icon" /> */}
              <ListDropdownItem
                className={`${hindiRegular}`}
                style={{ width: "100%" }}
              >
                <DropdownItemHeader
                  onClick={() => handleActiveDropdown("businessinsproducts")}
                  className={hindiRegular}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <StyledLink
                    to="/fire"
                    className={`mx-nav-event ${hindiRegular}`}
                  >
                    {t("Products.Items.0.Name")}
                  </StyledLink>
                  <ChevronDown className="chevron-down" />
                </DropdownItemHeader>
                <ul
                  className={
                    activeDropdown["businessinsproducts"] ? "dropped-down" : ""
                  }
                >
                  <li>
                    <StyledLink
                      to="/business-type/shop"
                      className={`mx-nav-event ${hindiRegular}`}
                    >
                      {t("Products.Items.0.Shop")}
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink
                      to="/business-type/restaurant"
                      className={`mx-nav-event ${hindiRegular}`}
                    >
                      {t("Products.Items.0.Restaurant")}
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink
                      to="/business-type/factory"
                      className={`mx-nav-event ${hindiRegular}`}
                    >
                      {t("Products.Items.0.Factory")}
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink
                      to="/business-type/storage"
                      className={`mx-nav-event ${hindiRegular}`}
                    >
                      {t("Products.Items.0.Storage")}
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink
                      to="/business-type/service-business"
                      className={`mx-nav-event ${hindiRegular}`}
                    >
                      {t("Products.Items.0.Service")}
                    </StyledLink>
                  </li>
                </ul>
              </ListDropdownItem>
            </li>
            <li>
              {/* <LiabilityIcon className="navbar-drop-icon" /> */}
              <StyledLink
                to="/liability"
                className={`mx-nav-event ${hindiRegular}`}
              >
                {t("Products.Items.1")}
              </StyledLink>
            </li>
            <li>
              <StyledLink
                to="/safety-plan"
                className={`mx-nav-event ${hindiRegular}`}
              >
                {t("Products.Items.2")}
              </StyledLink>
            </li>
          </ul>
        </DropdownItem>
        <DropdownItem>
          <StyledLink to="/claims" className={`mx-nav-event ${hindiBold}`}>
            {t("Claims")}
          </StyledLink>
        </DropdownItem>
        <DropdownItem>
          <StyledLink
            to="/endorsements"
            className={`mx-nav-event ${hindiBold}`}
          >
            {t("Endorsements")}
          </StyledLink>
        </DropdownItem>
        <DropdownItem>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1rem 0",
            }}
            onClick={() => handleActiveDropdown("blogs")}
            className={hindiBold}
          >
            {t("Blogs.Text")}
            <ChevronDown className="chevron-down" />
          </div>
          <ul className={activeDropdown["blogs"] ? "dropped-down" : ""}>
            <li>
              <a
                href="https://insights.verak.in/"
                style={{ paddingBottom: "0px" }}
                className={`mx-nav-event ${hindiRegular}`}
              >
                {t("Blogs.Items.0")}
              </a>
            </li>
            <li>
              <a
                href="https://blog.verak.in/"
                className={`mx-nav-event ${hindiRegular}`}
              >
                {t("Blogs.Items.1")}
              </a>
            </li>
          </ul>
        </DropdownItem>
        <DropdownItem>
          <DropdownItemHeader
            onClick={() => handleActiveDropdown("aboutus")}
            className={hindiBold}
          >
            {t("AboutUs.Text")}
            <ChevronDown className="chevron-down" />
          </DropdownItemHeader>
          <ul className={activeDropdown["aboutus"] ? "dropped-down" : ""}>
            <li>
              <StyledLink
                to="/our-company"
                className={`mx-nav-event ${hindiRegular}`}
              >
                {t("AboutUs.Items.0")}
              </StyledLink>
            </li>
            <li>
              <StyledLink
                to="/our-team"
                className={`mx-nav-event ${hindiRegular}`}
              >
                {t("AboutUs.Items.1")}
              </StyledLink>
            </li>
            <li>
              <StyledLink
                to="/our-customers"
                className={`mx-nav-event ${hindiRegular}`}
              >
                {t("AboutUs.Items.2")}
              </StyledLink>
            </li>
          </ul>
        </DropdownItem>
        <DropdownItem>
          <a
            href="https://client.verak.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Login")}
          </a>
        </DropdownItem>
        {showLangSwitcher && (
          <DropdownItem style={{ padding: "0.5rem 0", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold",
              }}
              className={hindiBold}
            >
              {t("Language")}
              <LanguageSwitcher />
            </div>
          </DropdownItem>
        )}
        <div
          style={{
            padding: "30px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a
            className="social-icons"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/verak"
          >
            <StaticImage
              alt="linkedin"
              src="../assets/images/linkedin_small.png"
              placeholder="#FFF7F1"
              loading="lazy"
            />
          </a>
          <a
            className="social-icons"
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/VerakInsurance"
          >
            <StaticImage
              alt="twitter"
              src="../assets/images/twitter_small.png"
              placeholder="#FFF7F1"
              loading="lazy"
            />
          </a>

          <a
            className="social-icons"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/VerakInsurance"
          >
            <StaticImage
              alt="facebook"
              src="../assets/images/facebook_small.png"
              placeholder="#FFF7F1"
              loading="lazy"
            />
          </a>
          <a
            className="social-icons"
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/919769161630?text=Hey"
          >
            <StaticImage
              alt="whatsapp"
              src="../assets/images/whatsapp_small.png"
              placeholder="#FFF7F1"
              loading="lazy"
            />
          </a>
          <a
            className="social-icons"
            target="_blank"
            rel="noopener noreferrer"
            href="https://bit.ly/VerakWebYT"
          >
            <StaticImage
              alt="youtube"
              src="../assets/images/youtube_small.png"
              placeholder="#FFF7F1"
              loading="lazy"
            />
          </a>
          <a
            className="social-icons"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/verakinsurance/"
          >
            <StaticImage
              alt="instagram"
              src="../assets/images/instagram_small.png"
              placeholder="#FFF7F1"
              loading="lazy"
            />
          </a>
        </div>
      </MobileNavDropdown>
      <NavBackground active={isNavActive} />
    </>
  );
}

const LoginLinkDesktop = styled.a`
  margin: auto 1rem;
  align-self: center;
  color: #f45e00;
  font-family: Plus Jakarta Sans Bold;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const Burger = styled.div`
  display: none;
  margin-left: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: ${({ active }) => `${active ? "rotate(-90deg)" : ""}`};

  div {
    width: 25px;
    height: 4px;
    margin: 5px;
    background-color: ${({ active }) => `${active ? "#FF934F" : "#4c4c4c"}`};
  }

  @media screen and (max-width: 1000px) {
    display: block;
  }
`;

const NavList = styled.ul`
  --gap-hi-offset: ${(p) => (p.isHindi ? "12px" : "0px")};
  display: flex;
  justify-content: start;
  align-items: center;
  gap: calc(36px + var(--gap-hi-offset));
  @media screen and (max-width: 1350px) {
    gap: calc(28px + var(--gap-hi-offset));
  }
  @media screen and (max-width: 1150px) {
    gap: calc(20px + var(--gap-hi-offset));
  }
  @media screen and (max-width: 1080px) {
    gap: calc(12px + var(--gap-hi-offset));
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
`;

const NavItems = styled(Flex)`
  --margin-left-hi-offset: ${(p) => (p.isHindi ? "60px" : "0px")};
  justify-content: space-between;
  margin-left: calc(105px + var(--margin-left-hi-offset));
  @media (max-width: 1435px) {
    margin-left: calc(75px + var(--margin-left-hi-offset));
  }
  @media (max-width: 1350px) {
    margin-left: calc(30px + var(--margin-left-hi-offset));
  }
  @media (max-width: 1250px) {
    margin-left: calc(0px + var(--margin-left-hi-offset));
  }
  @media (max-width: 1150px) {
    margin-left: ${(p) => (p.isHindi ? "30px" : "0px")};
  }
  @media (max-width: 1080px) {
    margin-left: 0px;
  }
  @media (max-width: 1000px) {
    justify-content: flex-end;
    margin-left: 0;
  }
`;

const Link = styled.a`
  color: inherit;
`;

const ListItemDropdown = css`
  &:hover .drop,
  &:focus .drop {
    opacity: 1;
    visibility: visible;
  }

  .drop-with-children:hover .drop-child,
  .drop-with-children:hover .drop-child {
    opacity: 1;
    visibility: visible;
  }

  &:hover,
  &:focus {
    color: #f45e00;
  }

  .drop-child,
  .drop {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 4.5rem;
    left: -4rem;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 5px;
    width: 15rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    color: #333333;
    transition: opacity 200ms ease-in-out;
    height: fit-content;
    z-index: 9999;
    transform: translateY(-15px);

    &:after {
      content: "";
      position: absolute;
      top: -7px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid white;
    }

    &:hover,
    &:focus {
      opacity: 1;
      visibility: visible;
    }

    a {
      border-bottom: 0.5px solid #ececec;

      &:last-child {
        border-bottom: none;
      }
    }

    li {
      list-style: none;
      padding: 10px 12px;
      border-radius: 5px;
      font-size: 14px;
      transition: background-color 200ms ease-in-out;

      &:hover,
      &:focus {
        color: #f45e00;
      }
    }
  }

  .drop-child {
    left: calc(100% - 1rem);
    top: 1rem;
    right: -2rem;

    &:after {
      content: "";
      position: absolute;
      right: auto;
      left: -7px;
      top: 1.5rem;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-left: none;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 7px solid white;
    }
  }
`;

const ListDropdownItem = styled.div`
  color: inherit;
  text-decoration: none;
`;

const DropdownItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 0.5px solid #cccccc;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

const LanguageSwitcherBtn = styled(Button)`
  list-style: none;
  position: relative;
  display: block;
  min-width: 130px;
  height: 48px;
  padding: 0.25rem;
  margin: 0 0.75rem;
  background: #fff;
  border: 1px solid #fabc96;
  box-shadow: none;
  border-radius: 14px;
  text-align: center;
  cursor: pointer;
  ${ListItemDropdown};
  @media (max-width: 768px) {
    height: 40px;
  }
`;

export const CustomButton = styled(Button)`
  height: 48px;
  padding: 0.5rem 0.75rem;
  font-size: 20px;
  min-width: 165px;
  @media screen and (max-width: 768px) {
    padding: 8px;
    font-size: 18px;
    min-width: 130px;
  }
  @media screen and (max-width: 350px) {
    min-width: 90px;
    width: fit-content;
    font-size: 14px;
  }
`;

const ListItem = styled.li`
  list-style: none;
  position: relative;
  padding: 1.5rem 0.25rem;
  cursor: pointer;
  font-size: 16px;
  color: ${({ active }) => `${active ? "#f45e00" : "#333333"}`};
  font-weight: 500;
  ${ListItemDropdown};
`;

const LogoText = styled.div`
  color: #7a7a7a;
  font-size: 10px;
  margin-top: 4px;
  width: 188px;

  @media screen and (max-width: 768px) {
    font-size: 8px;
    width: 150px;
  }
`;

const Logo = styled(VerakLogo)`
  height: 28px;
  width: 134px;

  @media screen and (max-width: 768px) {
    width: 120px;
  }
`;

const Wrapper = styled(Flex)`
  height: 100%;
  width: 100%;
  max-width: 1440px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 80px;

  @media screen and (max-width: 768px) {
    padding: 0px 14px;
  }

  li {
    line-height: 20px;
  }
`;

const NavbarWrapper = styled.div`
  height: 80px;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
`;

const DropdownItem = styled.div`
  font-size: 18px;
  color: #4c4c4c;
  font-weight: bold;
  display: flex;
  justify-content: center;
  border-bottom: 0.5px solid #cccccc;
  flex-direction: column;

  a {
    color: inherit;
    text-decoration: none;
    padding: 16px 0px;
    margin: 0 !important;
  }

  .chevron-down {
    transform: rotate(180deg);
  }

  ul {
    visibility: hidden;
    height: 0px;
  }

  .dropped-down {
    height: fit-content;
    visibility: visible;
  }

  li {
    padding: 10px;
    padding-top: 0px;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;

    .navbar-drop-icon {
      width: 34px;
      margin-right: 15px;
    }
  }
`;

const MobileNavDropdown = styled.div`
  display: none;
  transition: transform 0.3s ease-in;

  @media screen and (max-width: 1000px) {
    display: block;
    background-color: #fff;
    padding: 0px 16px;
    position: fixed;
    z-index: 1020;
    top: 80px;
    height: fit-content;
    width: 100vw;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    transform: ${({ active }) =>
      `${active ? "translateY(0%)" : "translateY(-100%)"}`};

    .social-icons {
      margin: 0px 15px;
    }
  }
`;

const NavBackground = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: ${({ active }) => `${active ? "block" : "none"}`};
    position: fixed;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    top: 0;
    left: 0;
    transition: opacity 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: ${({ active }) => `${active ? "1" : "0"}`};
  }
`;
