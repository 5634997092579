import { isBrowser } from "./browserUtils"

export const getPageName = () => {
    const pathname = isBrowser ? window.location.pathname : ""
    if (pathname.includes('/glp1')) {
        return "new GLP1"
    } else if (pathname.includes('/glp2')) {
        return "new GLP2"
    } else if (pathname.includes('/glp3')) {
        return "new GLP3"
    } else if (pathname.includes('/glp4')) {
        return "new GLP4"
    } else if (pathname.includes('/glp5')) {
        return "new GLP5"
    } else if (pathname.includes('/glp6')) {
        return "new GLP6"
    } else if (pathname.includes('/glp7')) {
        return "new GLP7"
    } else if (pathname.includes('/glp8')) {
        return "new GLP8"
    } else if (pathname.includes('/glp9')) {
        return "new GLP9"
    } else if (pathname.includes('/fblp1')) {
        return "FBLP1"
    } else if (pathname.includes('/fblp2')) {
        return "FBLP2"
    } else if (pathname.includes('/fblp3')) {
        return "FBLP3"
    } else if (pathname.includes('/fbsft1')) {
        return "FBSFT1"
    } else if (pathname.includes('/fbsft2')) {
        return "FBSFT2"
    }
    return ""
}