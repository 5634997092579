import React from "react";
import { leadformReducer } from "./LeadFormReducer";

// const initialState = {
//   mobile: "1234567890",
//   customerName: 'Test',
//   companyName: 'Test',
//   pincode: '400001',
// }
// const initialState = {}
const initialState = null

export const LeadFormContext = React.createContext(initialState)

export const useLeadFormData = () => {
  const leadFormContext = React.useContext(LeadFormContext)
  if (!leadFormContext) {
		throw new Error('useLeadFormData must be used within LeadFormProvider')
	}
  return leadFormContext
}

export const LeadFormProvider = ({ children, ...props }) => {
  const [state, dispatch] = React.useReducer(leadformReducer, initialState)
	const value = React.useMemo(() => [state, dispatch], [state])

	return <LeadFormContext.Provider value={value}>{children}</LeadFormContext.Provider>
}