export const actions = {
  setLeadData: 'SET_LEAD_DATA',
  setBusinessTypeData: 'SET_BUSINESS_TYPE_DATA',
  setRoofWallsData: 'SET_ROOF_WALLS_DATA',
  setStockData: 'SET_STOCK_DATA',
  setSumInsuredData: 'SET_SUM_INSURED_DATA',
  setAddressData: 'SET_ADDRESS_DATA',
  setAllLeadFormData: 'SET_ALL_LEAD_FORM_DATA'
}

export const leadformReducer = (state, action) => {
  const data = action?.data || {}

  switch(action.type) {
    case 'SET_LEAD_DATA': 
      return {
        ...state,
        companyName: data.companyName,
        customerName: data.customerName,
        mobile: data.mobile,
        waUpdates: data.waUpdates,
      }
    case 'SET_BUSINESS_TYPE_DATA': 
      return {
        ...state,
        typeOfFacility: data.typeOfFacility,
        natureOfBusiness: data.natureOfBusiness,
      }
    case 'SET_ROOF_WALLS_DATA': 
      return {
        ...state,
        roofMaterial: data.roofMaterial,
        wallMaterial: data.wallMaterial,
      }
    case 'SET_STOCK_DATA': 
      return {
        ...state,
        goods: data.goods, // only for shops & 'other' ToFs
        rawMaterials: data.rawMaterials,
        finishedGoods: data.finishedGoods,
        sumInsured: data.sumInsured,
        sumInsuredValues: {
          ...data.sumInsuredValues
        },
      }
    case 'SET_SUM_INSURED_DATA': 
      return {
        ...state,
        ownership: data.ownership,
        sumInsured: data.sumInsured,
        sumInsuredValues: {
          ...data.sumInsuredValues
        },
        otherItemsDesc: data.otherItemsDesc,
      }
    case 'SET_ADDRESS_DATA': 
      return {
        ...state,
        pincode: data.pincode,
        address: data.address,
        city: data.city,
        stateVal: data.stateVal,
        floor: data.floor,
      }
    case 'SET_ALL_LEAD_FORM_DATA': 
      return {
        ...state,
        ...data
      }
    default: 
      return state
  }
}