const nonTranslatedPages = [
    '/privacy',
    '/refund-policy',
    '/terms',
    '/campaigns/facebook',
    '/campaigns/google',
    '/campaigns/google1',
    '/campaigns/google3',
    '/campaigns/google3v1',
    '/business-type/shop',
    '/business-type/restaurant',
    '/business-type/factory',
    '/business-type/service-business',
    '/business-type/storage',
    '/business-insurance',
    '/safety-plan'
]

export default nonTranslatedPages