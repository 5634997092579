import { useTranslation } from "gatsby-plugin-react-i18next"
import { isBrowser } from "../utils/browserUtils"
import nonTranslatedPages from "../utils/getNonTranslatedPages"

// to strip any trailing backslashes, if present
const getCleanedPathname = (path) => path !== '/' ? path.replace(/\/+$/, "") : path

export default function useHindiLangFont(enableTranslations = true) {
    const {i18n} = useTranslation()
    const isHindi = i18n.language === 'hi' && enableTranslations && isBrowser && !nonTranslatedPages.includes(getCleanedPathname(window.location?.pathname?.replace('/hi/', '/')))

    return ({
        hindiRegular: isHindi ? "hindi-regular" : "",
        hindiBold: isHindi ? "hindi-bold" : "",
        hindiBlack: isHindi ? "hindi-black" : ""
    })
}