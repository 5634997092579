import React from "react";
import { isBrowser } from "../utils/browserUtils";

export const screens = {
  heroImage: "hero-image",
  claimTestimonial: "claim-testimonial",
  totalStats: "total-stats",
  regionalStats: "regional-stats",
  factsAboutUs: "facts-about-us",
  selfServe: "self-serve"
}

export const useCampaignLPStates = () => {
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);
  const [hideQuoteCTAs, setHideQuoteCTAs] = React.useState(false)
  const [hideMinPremium, setHideMinPremium] = React.useState(false)
  const [screen, setScreen] = React.useState(() => {
    if (isBrowser && window.location.href.includes("/self-serve")) {
      return screens.selfServe
    }
    return screens.heroImage
  })
  const leadformWrapperRef = React.useRef(null)
  const leadformRef = React.useRef(null)
  const selfServeRef = React.useRef(null)

  const hideHeroSection = screen === screens.regionalStats
  const isSelfServe = screen === screens.selfServe

  const scrollToLeadForm = () => {
    if (isBrowser && leadformWrapperRef?.current) {
      const childComp = leadformWrapperRef.current.firstChild
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
      childComp.classList.add('highlight-container')
		  setTimeout(() => {
        childComp.classList.remove('highlight-container')
      }, 2000)
    }
  }

  // toggle the call schedule modal on the navbar
  const handleCallScheduleClick = () => {
    selfServeRef.current.showCallSchedulePopup()
  } 

  // for the edge case when self serve is not possible
  const fetchEstimatedPremium = async () => {
    await leadformRef.current.fetchEstimatedPremium()
  }

  return{
    leadformWrapperRef,
    leadformRef,
    scrollToLeadForm,
    isQuoteModalOpen,
    setIsQuoteModalOpen,
    hideQuoteCTAs,
    setHideQuoteCTAs,
    hideMinPremium,
    setHideMinPremium,
    screen, 
    setScreen,
    hideHeroSection,
    isSelfServe,
    fetchEstimatedPremium,
    selfServeRef,
    handleCallScheduleClick
  }
}