import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { useMixpanel } from "../context/MixpanelContext";
import { StyledGatsbyLink, StyledLink } from "./core/commonExports";
import useHindiLangFont from "../hooks/useHindiLangFont";

export const Footer = ({ style={}, useNewAddress = false}) => {
  const {t} = useTranslation('footer')
  const mixpanel = useMixpanel();
  const { hindiBold, hindiRegular } = useHindiLangFont()

  const sendMixpanelEvent = (mxEventName) => {
    mixpanel.track(mxEventName);
  };

  return (
    <div className="footer-wrapper" style={style}>
      <div
        className="wrapper"
        style={{ marginTop: "80px", position: "relative" }}
      >
        <div className="footer-main-container">
          <div className="section-container" style={{ alignItems: "start" }}>
            <div className="left-section">
              <h4 className={`footer-head-text ${hindiBold}`}>
                {t('CompanyData.Name')}
              </h4>
              <div className="company-section-container">
                <p
                  className={`footer-normal-text ${hindiRegular}`}
                  style={{ marginBottom: "10px" }}
                >
                  <Trans i18nKey="CompanyData.LicenseDetails">
                    IRDAI License no. 673 <br />
                    Direct Broker (Life & General) <br />
                    Valid upto 27/03/2025
                  </Trans>
                </p>
              </div>
              <div className="on-mobile">
                <h4 className={`footer-head-text ${hindiBold}`}>{t('SocialLinksSection.Title')}</h4>
                <div className="flex-div" style={{ marginTop: "10px" }}>
                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="tel:+919076277062"
                  >
                    <StaticImage
                      alt="phone-icon"
                      src="../assets/images/phone_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                    />
                  </a>

                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/verak"
                  >
                    <StaticImage
                      alt="linkedin"
                      src="../assets/images/linkedin_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                    />
                  </a>

                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/VerakInsurance"
                  >
                    <StaticImage
                      alt="twitter"
                      src="../assets/images/twitter_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                      height="26px"
                    />
                  </a>

                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/VerakInsurance"
                  >
                    <StaticImage
                      alt="facebook"
                      src="../assets/images/facebook_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                      height="26px"
                    />
                  </a>
                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://wa.me/919769161630?text=Hey"
                  >
                    <StaticImage
                      alt="whatsapp"
                      src="../assets/images/whatsapp_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                      height="26px"
                    />
                  </a>
                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://bit.ly/VerakWebYT"
                  >
                    <StaticImage
                      alt="youtube"
                      src="../assets/images/youtube_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                      height="26px"
                    />
                  </a>
                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/verakinsurance/"
                  >
                    <StaticImage
                      alt="instagram"
                      src="../assets/images/instagram_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                      height="26px"
                    />
                  </a>
                </div>

                <h4 className={`footer-head-text ${hindiBold}`} style={{ marginTop: "10px" }}>
                  {t('IRDAICertSection.Title')}
                </h4>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://verak-assets.s3.ap-south-1.amazonaws.com/Girnar+IRDAI+License.pdf"
                >
                  <div
                    className="flex-div"
                    style={{ alignItems: "flex-end", marginTop: "10px" }}
                  >
                    <StaticImage
                      style={{ cursor: "pointer" }}
                      alt="irdai certificate"
                      src="../assets/images/irdai_certificate.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                    />
                    <StaticImage
                      style={{ marginLeft: "-1.4rem" }}
                      placeholder="#000"
                      src="../assets/images/download_icon.png"
                    />
                  </div>
                </a>
              </div>
            </div>

            <div>
              <div className="on-desktop">
                <div style={{ marginTop: "28px" }}></div>
              </div>
              <p className={`footer-head-text ${hindiBold}`} style={{ marginBottom: "10px" }}>
                {t('Contact.Title')}
              </p>
              <div className="on-mobile">
                <p
                  className={`footer-normal-text ${hindiRegular}`}
                  style={{ marginBottom: "5px", fontWeight: "bold" }}
                >
                  {t('Contact.Email')}:
                </p>
              </div>
              <div
                className="flex-div"
                style={{ alignItems: "center", marginBottom: "10px" }}
              >
                <div className="on-desktop">
                  <img
                    alt="email"
                    src="https://verak-assets.s3.ap-south-1.amazonaws.com/mailIcon.svg"
                    style={{ marginRight: "10px" }}
                  />
                </div>
                <p className={`footer-normal-text ${hindiRegular}`}>
                  <a href="mailto:admin@verak.in">admin@verak.in</a>
                </p>
              </div>
              <div className="on-mobile">
                <p
                  className={`footer-normal-text ${hindiRegular}`}
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {t('Contact.Address')}:
                </p>
              </div>
              <div className="flex-div" style={{ alignItems: "start" }}>
                <div className="on-desktop">
                  <img
                    alt="address"
                    src="https://verak-assets.s3.ap-south-1.amazonaws.com/locationIcon.svg"
                    style={{ marginRight: "10px" }}
                  />
                </div>
                <p className={`footer-normal-text ${hindiRegular}`}>
                {useNewAddress ? (
                    <>
                      Office no. 316, 3rd floor, Rupa Solitaire building,
                      <br />
                      Millennium Business Park, Thane - Belapur Road,
                      <br />
                      Mahape, Navi Mumbai - 400710
                    </>
                  ) : (
                    <Trans i18nKey="Contact.AddressData">
                      21 GIRNAR,
                      <br />
                      Govind Marg, Moti Doongri Road, 
                      <br />
                      Dharam Singh Circle, Jaipur 
                      <br />
                      Rajasthan, India, 302004
                    </Trans>
                  )}
                </p>
              </div>
              <div className="on-mobile">
                <p
                  className={`footer-normal-text ${hindiRegular}`}
                  style={{
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {t('Contact.Phone')}:
                </p>
              </div>
              <div className="flex-div" style={{ alignItems: "center", marginTop: "5px" }}>
                <div className="on-desktop">
                  <StaticImage
                    alt="phone-icon"
                    src="../assets/images/phone_small.png"
                    loading="lazy"
                    style={{ marginRight: "5px" }}
                  />
                </div>
                <p className={`footer-normal-text`}>
                  <a href="tel:+919076277062">+91 9076277062</a>
                </p>
              </div>
            </div>
          </div>
          <div className="company-main-section">
            <h4 className={`footer-head-text ${hindiBold}`}>{t('ResourcesSection.Title')}</h4>
            <div
              className="company-section-container"
              style={{
                justifyContent: "start",
                width: "100%",
                color: "#000",
                fontSize: "12px",
              }}
            >
              <StyledLink
                to="/claims"
                onClick={() => sendMixpanelEvent("Clicked 'Claims' - Footer")}
                className={hindiRegular}
              >
                {t('ResourcesSection.Claims')}
              </StyledLink>
              <StyledLink
                to="/endorsements"
                onClick={() => sendMixpanelEvent("Clicked 'Endorsements' - Footer")}
                className={hindiRegular}
              >
                {t('ResourcesSection.Endorsements')}
              </StyledLink>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://insights.verak.in/"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Verak Insights' - Footer")
                }
                className={hindiRegular}
              >
                {t('ResourcesSection.VerakInsights')}
              </a>
              <a
                href="https://blog.verak.in/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Verak Explainers' - Footer")
                }
                className={hindiRegular}
              >
                {t('ResourcesSection.VerakExplainers')}
              </a>
              <a
                href="https://client.verak.in/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Client Portal' - Footer")
                }
                className={hindiRegular}
              >
                {t('ResourcesSection.ClientLogin')}
              </a>
            </div>
          </div>
          <div className="company-main-section">
            <h4 className={`footer-head-text ${hindiBold}`}>{t('ProductsSection.Title')}</h4>
            <div className="company-section-container">
              <StyledGatsbyLink to="/fire" className={`footer-subheader-text ${hindiBold}`} onClick={() => sendMixpanelEvent("Clicked  'Fire & Allied Perils' - Footer")}>{t('ProductsSection.BusinessPropertyInsurance')}</StyledGatsbyLink>
            </div>
            <div
              className="company-section-container"
              style={{
                justifyContent: "start",
                width: "100%",
                color: "#000",
                fontSize: "12px",
                paddingTop: "0"
              }}
            >
              <StyledLink
                to="/business-type/shop"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Shop' - Footer")
                }
                className={hindiRegular}
              >
                {t('ProductsSection.Shop')}
              </StyledLink>
              <StyledLink
                to="/business-type/restaurant"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Restaurant' - Footer")
                }
                className={hindiRegular}
              >
                {t('ProductsSection.Restaurant')}
              </StyledLink>
              <StyledLink
                to="/business-type/factory"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Factory' - Footer")
                }
                className={hindiRegular}
              >
                {t('ProductsSection.Factory')}
              </StyledLink>
              <StyledLink
                to="/business-type/storage"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Storage' - Footer")
                }
                className={hindiRegular}
              >
                {t('ProductsSection.Storage')}
              </StyledLink>
              <StyledLink
                to="/business-type/service-business"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Service' - Footer")
                }
                className={hindiRegular}
              >
                {t('ProductsSection.Service')}
              </StyledLink>
            </div>
            <div className="company-section-container" style={{paddingTop: "6px"}}>
              <StyledLink
                to="/liability"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Liability Insurance' - Footer")
                }
                className={`footer-subheader-text ${hindiBold}`}
              >
                {t('ProductsSection.LiabilityInsurance')}
              </StyledLink>
            </div>
            <div className="company-section-container" style={{paddingTop: "6px"}}>
              <StyledLink
                to="/safety-plan"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Safety Plan' - Footer")
                }
                className={`footer-subheader-text ${hindiBold}`}
              >
                {t('ProductsSection.SafetyPlan')}
              </StyledLink>
            </div>
          </div>
          <div className="company-main-section">
            <h4 className="footer-head-text">{t('CompanySection.Title')}</h4>
            <div
              className="company-section-container"
              style={{
                justifyContent: "start",
                width: "100%",
                color: "#000",
                fontSize: "12px",
              }}
            >
              {/* <StyledLink
                to="/aboutus"
                onClick={() => sendMixpanelEvent("Clicked 'About Us' - Footer")}
                className={hindiRegular}
              >
                {t('CompanySection.AboutUs')}
              </StyledLink> */}
              <StyledLink
                to="/our-company"
                onClick={() => sendMixpanelEvent("Clicked 'Company' - Footer")}
                className={hindiRegular}
              >
                {t('CompanySection.Company')}
              </StyledLink>
              <StyledLink
                to="/our-team"
                onClick={() => sendMixpanelEvent("Clicked 'Team' - Footer")}
                className={hindiRegular}
              >
                {t('CompanySection.Team')}
              </StyledLink>
              <StyledLink
                to="/our-customers"
                onClick={() => sendMixpanelEvent("Clicked 'Customers' - Footer")}
                className={hindiRegular}
              >
                {t('CompanySection.Customers')}
              </StyledLink>
              <StyledGatsbyLink
                to="/privacy"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Privacy Policy' - Footer")
                }
                className={hindiRegular}
              >
                {t('CompanySection.PrivacyPolicy')}
              </StyledGatsbyLink>
              {/* <StyledLink
                to="/refund-policy"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Refund Policy' - Footer")
                }
              >
                Refund Policy
              </StyledLink> */}
              <StyledGatsbyLink
                to="/terms"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Terms & Conditions' - Footer")
                }
                className={hindiRegular}
              >
                {t('CompanySection.Terms&Conditions')}
              </StyledGatsbyLink>
            </div>
          </div>
          <div className="company-main-section on-desktop">
            <h4 className={`footer-head-text ${hindiBold}`}>{t('SocialLinksSection.Title')}</h4>

            <div className="flex-div" style={{ marginTop: "10px" }}>
              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919076277062"
              >
                <StaticImage
                  alt="phone-icon"
                  src="../assets/images/phone_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="20px"
                />
              </a>
              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/verak"
              >
                <StaticImage
                  alt="linkedin"
                  src="../assets/images/linkedin_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="20px"
                />
              </a>

              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/VerakInsurance"
              >
                <StaticImage
                  alt="twitter"
                  src="../assets/images/twitter_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="26px"
                />
              </a>

              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/VerakInsurance"
              >
                <StaticImage
                  alt="facebook"
                  src="../assets/images/facebook_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="26px"
                />
              </a>
              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/919769161630?text=Hey"
              >
                <StaticImage
                  alt="whatsapp"
                  src="../assets/images/whatsapp_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="20px"
                />
              </a>

              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://bit.ly/VerakWebYT"
              >
                <StaticImage
                  alt="youtube"
                  src="../assets/images/youtube_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="26px"
                />
              </a>

              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/verakinsurance/"
              >
                <StaticImage
                  alt="instagram"
                  src="../assets/images/instagram_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="26px"
                />
              </a>
            </div>

            <h4 className={`footer-head-text ${hindiBold}`} style={{ marginTop: "28px" }}>
              {t('IRDAICertSection.Title')}
            </h4>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://verak-assets.s3.ap-south-1.amazonaws.com/Girnar+IRDAI+License.pdf"
            >
              <div
                className="flex-div"
                style={{ alignItems: "flex-end", marginTop: "10px" }}
              >
                <StaticImage
                  alt="irdai ceritificate"
                  src="../assets/images/irdai_certificate.png"
                  style={{ cursor: "pointer", height: "120px", width: "120px" }}
                  placeholder="#FFF7F1"
                  loading="lazy"
                />
                <StaticImage
                  style={{ marginLeft: "-1.4rem" }}
                  placeholder="#000"
                  src="../assets/images/download_icon.png"
                />
              </div>
            </a>
          </div>
        </div>

        <div
          className="section section-border"
          style={{ padding: "30px 0px 60px 10px" }}
        >
          <ol className="disclaimer">
            <li className={hindiRegular}>
              {t('Disclaimer.0')}
            </li>
            <li className={hindiRegular}>
              {t('Disclaimer.1')}
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
