// get the previously selected language and redirect the user accordingly

import { navigate } from "gatsby"
import { useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import getLangRoute from "../utils/getLangRoute"
import nonTranslatedPages from "../utils/getNonTranslatedPages"
import { isBrowser } from "../utils/browserUtils"

// to strip any trailing backslashes, if present
const getCleanedPathname = (path) => path !== '/' ? path.replace(/\/+$/, "") : path

const useSelectedLanguage = () => {
    const { i18n } = useTranslation()
    const getSelectedLanguage = () => {
        if (isBrowser) {
            return window.localStorage?.getItem('lang') || ''
        }
        return ''
    }

    const redirectToSelectedLang = () => {
        const selectedLang = getSelectedLanguage()
        const defaultLang = i18n.language
        if (selectedLang && selectedLang !== defaultLang) {
            // if default lang of website not same as the stored selection, change language and redirect
            console.log("setting and redirecting...")
            i18n.changeLanguage(selectedLang)
            const changedRoute = getLangRoute(selectedLang)
            changedRoute && navigate(changedRoute)
        }
    }

    useEffect(() => {
        const cleanedPathname = getCleanedPathname(window.location?.pathname)
        if (!(isBrowser && nonTranslatedPages.includes(cleanedPathname))) {
            redirectToSelectedLang()
        }
    }, []) // eslint-disable-line
}

export default useSelectedLanguage